@import './custom/colors.scss';
@import './custom/typography.scss';
@import './custom/custom.scss';
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap");

body {
  //background-color: #7E7E7E;
  width: 100%;
  height: 100%;
  //font-family: "Inter" !important;
  //z-index: 1;
}

.bg {
  background-color: #F1F1F1;
}

.h-100vh {
  height: 100vh;
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #e1e1e1 #f8f8f8;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 10px;
}

*::-webkit-scrollbar-track {
  background: #f8f8f8;
}

*::-webkit-scrollbar-thumb {
  width: 7px;
  background-color: #e1e1e1;
  border-radius: 16px;
}

.avathar {
  width: 70px;
  height: 70px;
  cursor: pointer;

  .img {
    object-fit: cover;
  }
}

.navbar {
  width: 100%;
  height: 93px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  background-color: #FFFFFF;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar>* {
  background-color: #FFFFFF;
}

.navbar-icons {
  display: flex;
  align-items: center;
}

/* Add margins or padding as needed */
.icon-container {
  margin-left: 40px;
  /* Adjust margin as needed */
  color: rgb(22, 19, 19);
  /* Set your desired text color */
}


.heading1 {
  width: 100%;
  color: #292929;
}

.logo {
  width: 70px;
  height: 70px;
  // top: 9px;
  // left: 29px;

}

.pic {
  width: 52px;
  height: 52px;
  top: 50px;
  left: 1429px;
  // border: 1px solid #141E8C; 
  position: static;

}

.profile-pic {
  margin-left: 0.75rem !important;
  margin-right: 0.75rem !important;

  @media(max-width: 1000px) {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
}

.custom-indicators {
  display: flex;
  justify-content: flex-end;
  /* Move indicators to the right */
  align-items: center;
  /* Vertically center the indicators */
}

.typo {
  // font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  //line-height: 23px;
  letter-spacing: 0em;
  text-align: center;

}

.custom-menu {
  width: 240px;
  height: 50px;
  border-radius: 10px;
  box-shadow: #0000001A;
}

.img1 {
  width: 53px;
  height: 53px;
  top: 81px;
  left: 1196px;

  @media screen and (max-width: 768px) {
    width: 35px !important;
    height: 35px !important;
    object-fit: contain;
  }
}

.img1-txt {
  width: 150px;
  height: 43px;
  top: 96px;
  left: 1260px;
  font-weight: 600;
  font-size: 12px;
  color: #7E7E7E;

}

.img4 {
  width: 50px;
  height: 25px;
  top: 162px;
  left: 1196px
}

.img1-txt1 {
  width: 150px;
  height: 43px;
  //top: 96px;
  //left: 1260px;
  font-weight: 400;
  font-size: 14px;
  transform: translateX(-48%);
}

.link-style {
  text-decoration: none;
  color: #292929
}

//navbar
.nav {
  font-weight: 600;
  // font-family: sans-serif;
  font-size: 22px;
  top: 20px;
  left: 1097px;
}

.txt-styles {
  top: 40%;
}

.login-page-header {
  margin-top: 200px;
  text-align: start;
}

.search {
  right: 600px;
}

.head1 {
  width: 800px;
  height: 95px;
  //top: 649px;
  left: 201px;
  color: #141E8C
}

.head2 {
  // font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0em;
  color: #292929 //text-align: left;

}

.head3 {
  // font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 23px;
  color: #292929
}

.car-col {
  // background: rgb(152, 152, 152);
  background: linear-gradient(90deg, rgb(248,	249,	250) 0%, rgba(255, 255, 255, 1) 57%);
  // background: linear-gradient(90deg, rgba(20, 30, 140, 0.13) 13%, #7E7E7E 14%, #FFFFFF 57%);
  //border-left: 1px solid #141E8C;
  /* Greyish tinge to the left */
  //border-right: 1px solid #141E8C;
  /* Greyish tinge to the right */
}

.linear-gradient-light{
  background: linear-gradient(90deg, rgb(248,	249,	250) 0%, rgba(255, 255, 255, 1) 57%);
}


.logim {
  font: 0;
}

// .rounded {
//   border-radius: 50% !important;
// }

.nav-link.active {
  color: #0072ef !important;
  border-bottom: 2px solid #0072ef;
  width: max-content;
}

.fixed-footer {
  font-size: x-small;
  position: fixed;
  bottom: 0px;
  left: 0px;
  margin: auto;
  right: 0px;
  text-align: center;

  @media screen and (max-width: 768px) {

    background-color: white
      /* Example change for mobile view */
  }

}

.custom-grid{
  display: grid;
  grid-gap: 20px;

  .grid-template-columns-1-3{
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (min-width: 1200px) {
  .grid-template-columns-1-3 {
    grid-template-columns: repeat(3, 1fr);
  }
}

.cursor-pointer{
  cursor: pointer;
}

// custom navbar
.hamburger {
  position: absolute;
  right: 20px;
  top: 20px;
}

.menu-wrapper {
  background: $white;
  transition: .5s all;
  z-index: 1000;

  @include media-breakpoint-down(md) {

      position: absolute;
      top: 100%;
      left: 0;
      width: 100%;
      z-index: 1;

      box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075);

      ul,
      li {
          width: 100%;
      }
  }
}

.open-menu,
.close-menu {
  display: flex;
  flex-grow: 1;
}

.close-menu {
  @include media-breakpoint-down(md) {
      display: none;
  }
}

.nav-wrapper {
  li {
      padding: 0;
      margin: 0;
  }

  ul {
      padding: 0;
      margin: 0;
      list-style: none;

      .menu-item {
          position: relative;

          @include media-breakpoint-down(md) {
              border-bottom: 1px solid $primary;
          }

          &:hover {

              &>.sub-menu {
                  display: block;
              }
          }

          a {
              transition: .5s all;

              &:hover {
                  color: $secondary !important;
              }
          }
      }

      a {
          display: block;
          transition: .7s;
      }
  }
}


$font-family: "Poppins";
$phone: 320px;
$large-phone: 500px;
$tablet: 1024px;
$large-display: 1920px;

//font-size
$fs-36: 36px;
$fs-32: 32px;
$fs-28: 28px;
$fs-26: 26px;
$fs-24: 24px;
$fs-22: 22px;
$fs-20: 20px;
$fs-18: 18px;
$fs-16: 16px;
$fs-14: 14px;
$fs-12: 12px;
$fs-10: 10px;

$fw-light: 400;
$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;

@mixin fontSize($size) {
  $result: $size;

  @media only screen and (min-width: $large-display) {
    $result: $size;
    font-size: $result !important;
  }

  @media only screen and (max-width: $large-display) {
    $result: $size;
    font-size: $result !important;
  }

  @media only screen and (max-width: $tablet) {
    $result: calc(3px - $size);
    font-size: $result !important;

    .profile-pic {
      margin-left: 0 !important;
      margin-right: 0 !important;
    }
  }

  @media only screen and (max-width: $large-phone) {
    $result: calc($size - 6px);
    font-size: $result !important;

    .profile-pic {
      margin-left: 0;
      margin-right: 0;
    }
  }

  @media only screen and (max-width: $phone) {
    $result: calc(1px - $size);
    font-size: $result !important;
  }

}

.fs-x-sm-semibold {
  font-size: $fs-10 !important;
  font-weight: $fw-semibold !important;
}

.fs-sm-light {
  font-size: $fs-12 !important;
  font-weight: $fw-light !important;
}

.fs-sm-med {
  font-size: $fs-12 !important;
  font-weight: $fw-medium !important;
}

.fs-sm-semibold {
  font-size: $fs-12 !important;
  font-weight: $fw-semibold !important;
}

.fs-normal-light {
  font-size: $fs-14 !important;
  font-weight: $fw-light !important;
}

.fs-normal-med {
  font-size: $fs-14 !important;
  font-weight: $fw-medium !important;
}

.fs-normal-semibold {
  font-size: $fs-14 !important;
  font-weight: $fw-semibold !important;
}

.fs-sm-lg-light {
  font-size: $fs-16 !important;
  font-weight: $fw-light !important;
}

.fs-sm-lg-semibold {
  font-size: $fs-16 !important;
  font-weight: $fw-semibold !important;
}

.fs-med-lg-light {
  font-size: $fs-18 !important;
  font-weight: $fw-light !important;
}

.fs-med-lg-medium {
  font-size: $fs-18 !important;
  font-weight: $fw-medium !important;
}

.fs-med-lg-semibold {
  font-size: $fs-18 !important;
  font-weight: $fw-semibold !important;
}

.fs-med-slg-medium {
  font-size: $fs-20 !important;
  font-weight: $fw-medium !important;
}

.fs-med-slg-semibold {
  font-size: $fs-20 !important;
  font-weight: $fw-semibold !important;
}

.fs-lg-semibold {
  font-size: $fs-22 !important;
  font-weight: $fw-semibold !important;
}

.fs-xlg-light {
  font-size: $fs-24 !important;
  font-weight: $fw-light !important;
}

.fs-xlg-semibold {
  font-size: $fs-24 !important;
  font-weight: $fw-semibold !important;
}

.fs-xlg-bold {
  font-size: $fs-24 !important;
  font-weight: $fw-bold !important;
}

.fs-sm-heading-med {
  font-size: $fs-26 !important;
  font-weight: $fw-medium !important;
}

.fs-sm-heading-semibold {
  font-size: $fs-26 !important;
  font-weight: $fw-semibold !important;
}

.fs-med-heading-light {
  font-size: $fs-28 !important;
  font-weight: $fw-light !important;
}

.fs-lg-heading-light {
  @include fontSize($fs-32);
  font-weight: $fw-light !important;
}

.fs-lg-heading-med {
  @include fontSize($fs-32);
  font-weight: $fw-medium !important;
}

.fs-lg-heading-semibold {
  @include fontSize($fs-32);
  font-weight: $fw-semibold !important;
}

.fs-xlg-sm-heading-light {
  font-size: $fs-36 !important;
  font-weight: $fw-light !important;
}
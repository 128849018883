.active-orders-card {
  border-radius: 10px;

  @media only screen and (min-width: 764px) {
    width: 320px;
    height: 320px;
  }

  /* Other specific styles */
}

.active-complaints-card {
  @media only screen and (min-width: 764px) {
    width: 320px;
    height: 320px;
  }

  // top: 574px;
  // left: 25px;
  border-radius: 10px;
  /* Other specific styles */
}

.orders-card {
  border-radius: 10px;

  @media only screen and (min-width: 320px) {
    height: 80vh; //viewport
  }
}

.empty-card {
  width: 1280px;
  height: 330px;
  top: 512px;
  //left: 417px;
  border-radius: 10px
}

//cards on active orders
.custom-card {
  width: fit-content;
  /* You can adjust this width based on your layout, but keep it within 100% */
  height: fit-content;
  /* Adjust the height as needed */
  margin: 0;
  // border-top: 1px solid #7E7E7E;
  border-bottom: 1px solid #7E7E7E;
  color: linear-gradient(0deg, #7E7E7E, #7E7E7E),
    linear-gradient(0deg, #FFFFFF, #FFFFFF);
  //border-radius: 0;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.5);
}

.active-orders-head {
  // font-family: sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;

}

/* Common style for all cards */
.orders {
  width: 321.33px;
  height: 112px;
  border-radius: 10px;
  margin-bottom: 20px;
}

/* Style for the first card (light blue) */
.card-op {
  background-color: #3498DB1A;
}

/* Style for the second card (light purple) */
.card-oc {
  background-color: #141E8C1A;
}

/* Style for the third card (light yellow) */
.card-ip {
  background-color: #F1C40F1A;
}

/* Style for the fourth card (dark shade of blue) */
.card-od {
  background-color: #543CE71A;
  /* Dark shade of blue */
}

/* Style for the fifth card (dark shade of purple) */
.card-it {
  background-color: #9B59B61A;
  /* Dark shade of purple */
}

/* Style for the sixth card (light green) */
.card-oc2 {
  background-color: #2ECC711A
}

.card-cr {
  background-color: #FF00001A;
  /* Red color */
}

.small-card {
  border-radius: 10px;
  padding: 10px 34px;
  max-width: 80px;
}

.small-op {
  background-color: #3498DB;
}

.small-oc {
  background-color: #141E8C;
}

.small-ip {
  background-color: #F1C40F;
}

.small-od {
  background-color: #543CE7;
}

.small-it {
  background-color: #9B59B6;
}

.small-oc2 {
  background-color: #2ECC71;
}

.num {
  // top: 0px;
  // left: 600px ;
  //background-color: #007bff; /* Replace with your desired background color */
  color: #ffffff;
  /* Replace with your desired text color */
  //border-radius: 50%;
  // width: 30px;
  //height: 30px;
  //text-align: center;
  //line-height: 30px;
  // font-family: sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;

  width: 158px;
  height: 27px;
  top: 242px;
  left: 567px //transform: translateX(20%);
}

.txt {
  width: 158px;
  height: 27px;
  top: 280px;
  left: 569px;

  //ttransform: translateX(100%);
  color: #7E7E7E;
  // font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  //line-height: 23px;
  letter-spacing: 0em;
  //text-align: left;

}

.text2 {
  // font-family: sans-serif;
  font-size: 32px;
  font-weight: 600;
  line-height: 23px;
  letter-spacing: 0em;
  text-align: left;
  color: #FFFFFF;
}

// .num{
//   font-family: sans-serif;
//   font-size: 32px;
//   font-weight: 600;
//   line-height: 23px;
//   letter-spacing: 0em;
//   text-align: left;

// }
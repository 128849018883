// @font-face {
//   font-family: 'Frutiger Next';
//   src: url('../assets/Zeissfont/ZEISS_Font/ZEISSFrutigerNextW1G-Medium.ttf') format('truetype');
//   // font-weight: normal;
//   // font-style: normal;
// }

// @font-face {
//   font-family: 'zeiss-frutiger-next-condensed';
//   src: url('../assets/Zeissfont/ZEISS_Font/ZEISSFrutigerNextW1G-RgCn.ttf') format('truetype');
//   //font-weight: normal;
//   font-style: normal;
// }

.nav-style {
  font-family: 'Zeiss Frutiger Next W1G Medium';
  font-size: 20px;

  &.nav-active::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: -5px;
    height: 1.5px;
    width: 100%;
    background-color: $primary;

    @include media-breakpoint-down(md) {
      display: none;
    }
  }
}

.table-sub-txt{
  // font-family: 'Zeiss Frutiger Next W1G Medium';
  font-size: 14px;
}

.table-sub-title{
  // font-family: 'zeiss-frutiger-next-condensed';
  font-size: 12px;
  font-weight: 500;
}

.offer-sub{
  // font-family: 'Zeiss Frutiger Next W1G Medium',"Helvetica Neue", Helvetica, Verdana, Arial, sans-serif;
  font-size: 40px;
  font-weight: 600;
  @media screen and (max-width: 768px) {
    // font-family: 'Zeiss Frutiger Next W1G Medium', "Helvetica Neue", Helvetica, Verdana, Arial, sans-serif;
    font-size: 30px;
  }
}

.offer-head{
  // font-family: 'Zeiss Frutiger Next W1G Medium';
  font-size: 28px;
  font-weight: 500;
  @media screen and (max-width: 768px) {
    // font-family: 'Zeiss Frutiger Next W1G Medium';
    font-size: 20px;
  }

}
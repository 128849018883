.img {
    //width: fit-content;
    height: max-content;
    //top: 129px;
    //left: 155px;
    border-radius: 10px;
    border: 2px;
    background-color: linear-gradient(0deg, #C6C6C6, #C6C6C6);

}

.card-img {
    @media only screen and (min-width: 320px) {
        // width: 320px;
        // height: 320px;
    }

    //top: 145px;
    //left: 191px;
}

.card1 {
    width: fit-content;
    height: fit-content;
    //top: 129px;
    //left: 36px;
    border: 2px;
    border-radius: 10px;
    border: 2px solid #C6C6C6
}

.cards-container {
    //position: absolute;
    //top: 129px;
    //left: 36px;
    background: #292929;
    width: 100%;
    height: auto
}

// .card-style {
//     transform: translateX(40%);
// }

.detail{
    width: fit-content;
    height: 50%;
}
.card-botm {
    width: fit-content;
    height: max-content;
    //top: 571.01px;
    //left: 36px;
    border-radius: 10px, 10px, 0px, 0px;
    border-top: 2px solid #F1F1F1
}

.card-container-2 {
    display: flex;
    justify-content: flex-start;
    /* This can be 'flex-end' for right alignment */
    align-items: flex-start;
    /* Align vertically as needed */
}

.detail{
    width: 100%;
    height: '100vh';
    background:#ECF0F4;
}
.head1 {
    width: fit-content;
    height: max-content;
    //top: 505px;
    //left: 35px;

    //font-family: sans-serif;
    //font-size: 24px;
    //font-weight: 600;
    //line-height: 23px;
    //letter-spacing: 0em;
    //text-align: left;
    color: #292929;


}

.img-botm {
    width: fit-content;
    height: max-content;
    //top: 595.67px;
    //left: 177.16px;
    //padding: 8.21px, 8.21px, 8.21px, 8.21px;
    border-radius: 10px, 10px, 0px, 0px;
    border: 2px;
    //transform: translateX(80%);

}


.txt {
    width: fit-content;
    height: fit-content;
    //top: 778.57px;
    //left: 532.74px;
    border-radius: 10px, 10px, 0px, 0px;
    border: 2px;
    background-color: #F1F1F1;

}

//images inside the cards
.img-card1 {
    @media only screen and (min-width: 320px) {
        // width: 50px;
        height: 50px;
    }

    //top: 137px;
    //left: 46px
}

.txt1 {
    width: fit-content;
    height: fit-content;
    //top: 810.73px;
    //left: 63.41px;
    //font-family: sans-serif;
    //font-size: 22px;
    //font-weight: 400;
    //line-height: 23px;
    //letter-spacing: 0em;
    //text-align: left;
    color: #292929;
}

// .txt2 {
//     width: 75.38px;
//     height: 31.52px;
//     top: 854.95px;
//     left: 63.41px;
//     font-family: sans-serif;
//     font-size: 22px;
//     font-weight: 400;
//     line-height: 23px;
//     letter-spacing: 0em;
//     text-align: left;
//     color: #7E7E7E;


// }

// .txt3 {
//     width: 388px;
//     height: 28px;
//     top: 893px;
//     left: 63px;
//     font-family: sans-serif;
//     font-size: 16px;
//     font-weight: 600;
//     line-height: 23px;
//     letter-spacing: 0em;
//     text-align: left;
//     letter-spacing: 0em;
//     color: #7E7E7E
// }

.mainhead {
    width: fit-content;
    height: max-content;
    //top: 124px;
    //left: 535px;
    //font-family: sans-serif;
    //font-size: 32px;
    //font-weight: 600;
    //line-height: 43px;
    //letter-spacing: 0em;
    //text-align: left;
    //transform: translateX(100%);
    color: #141E8C;

}

@media only screen and (max-width: 767px) {
    .mainhead {
        font-size: $fs-20 !important;
        font-weight: $fw-semibold !important;
    }
}

@media only screen and (max-width: 767px) {
    .sub2 {
        font-size: $fs-18 !important;
        font-weight: $fw-semibold !important;
    }
}

// .sub1 {
//     font-family: Inter;
//     font-size: 24px;
//     font-weight: 600;
//     line-height: 43px;
//     letter-spacing: 0em;
//     text-align: left;

// }

// .sub2 {
//     transform: translateX(90%);
//     font-family: sans-serif;
//     font-size: 16px;
//     font-weight: 600;
//     line-height: 23px;
//     letter-spacing: 0em;
//     text-align: left;
// }

.sub3 {
    // transform: translateX(90%);
    width: fit-content;
    height: max-content;
    // top: 331px;
    // left: 537px;
    // font-family: sans-serif;
    // font-size: 14px;
    // font-weight: 400;
    // line-height: 23px;
    // letter-spacing: 0em;
    // text-align: left;


}

.sub4 {
    // transform: translateX(210%);
    width: fit-content;
    height: max-content;
    // top: 414px;
    // left: 539px;
    // font-family: sans-serif;
    // font-size: 14px;
    // font-weight: 400;
    // line-height: 23px;
    // letter-spacing: 0em;
    // text-align: left;
    color: #7E7E7E
}

.sub5 {
    width: fit-content;
    height: max-content;
    //top: 127px;
    //left: 537px;
    //transform: translateX(650%);

}

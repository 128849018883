@import "~bootstrap/scss/functions";

@import "./colors.scss";
@import "./typography.scss";
//@import "../font.scss";
@import "~bootstrap/scss/variables";
@import "~bootstrap/scss/variables-dark";

// Include any default map overrides here

@import "~bootstrap/scss/maps";
@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";

@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/helpers";

@import "~bootstrap/scss/utilities/api";

// Add additional custom code here
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/dropdown";


.text-grey {
    color: $grey !important;
}

.col-blue {
    background-color: $primary;
    color: white;
}

.text-blue {
    color: $primary
}

.text-yellow {
    color: $yellow;
}

.text-dark {
    color: $light-dark;
}

/* YourComponent.css */
.round-dropdown-menu {
    border-radius: 10px;
    /* Adjust the border-radius as needed */
}

.round-dropdown-menu::before {
    content: none;
    
}

#accountDropdown::after {
    content: none;
    
}

.rounded-state-circle {
    height: 14px;
    width: 14px;
    background: transparent;
    border-radius: 50px;
}

.border-primary{
    border: 1px solid $primary;
}

.br-50 {
    border-radius: 50px !important;
}

.card-badge {
    padding: 3px 8px;
}

@media (max-width: 767px) {
    .navbar-collapse.expanded {
        position: fixed;
        top: 0px; 
        left: 0;
        right: 0;
        z-index: 5000;
        background-color: #fff;
        padding: 1rem;
        height: calc(100vh - 0px);
    }
  
    .close-icon {
      position: absolute;
      top: 1rem;
      right: 1rem;
      cursor: pointer;
      background: transparent;
      border: none;
      font-size: 1.5rem;
    }
  }

.pagination-wrapper {
    position: fixed;
    bottom: 0;
    background-color: #ffffff;

    @media screen and (max-width: 768px) {
        bottom: 20px !important;
        /* Example change for mobile view */
    }
}

.pointer {
    cursor: pointer;
}

.br-10 {
    border-radius: 10px;
}

.custom-order-card {
    border-radius: 10px;
    height: fit-content;
}

.custom-padding {
    padding-bottom: 30px;
}

.main-carousel {
    .carousel-caption {
        left: 10% !important;
        top: 0px;
        right: 10% !important;
        text-align: left !important;
        padding: 0 !important;
        color: #141E8C;
    }

    .carousel-img {
        @media only screen and (min-width: 1280px) {
            width: 333px !important;
            height: 260px;
        }
    }

    .carousel-custom-wrapper {
        height: 360px;

        @media screen and (max-width: 768px) {
            height: 380px !important;
        }
    }
}

.rightbar {
    @media only screen and (min-width: 1280px) {
        width: 400px;
    }

    //height: 200vh;
}

.css-1ps6pg7-MuiPaper-root {
    box-shadow: none !important;
}

.css-aoeo82{
    box-shadow: none !important;
}
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    overflow-y: unset !important;
    max-width: none !important;
    max-height: none !important;
    min-height: max-content !important;
}


.small {
    font-size: x-small;
}

.box-shadow-none {
    box-shadow: none !important;
}

.main-carousel .carousel-custom-wrapper{
    height: 380px;
}


.notify-dropdown {
    right: 0 !important;
    left: auto !important;
    width: 200px;

    .nav-link {
        &:hover {
            background-color: rgba(0, 0, 0, 0.04);
        }
    }
}

.footer{
    justify-content: start;
   // padding-bottom: 0;
   // margin-bottom: 0;
    //position: fixed;
    width: 100%;
    background: #F2F5F8;
    @media screen and (max-width: 768px) {
        font-size: small;
    }
}

.hero-image{
    background: url(../../assets/login-poster.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
}


.btn-primary{
    background: $primary;
    color: #fff;
}

.logo-container{
  position: absolute;
}

.breadcrumbs{
    padding: 20px 0 12px 52px;

}

.css-11wg7o9-MuiTypography-root-MuiBreadcrumbs-root::before {
    content: "";
    //position: absolute;
    background-color: var(--color-white);
    left: 10px; /* Adjust the width of the gap at the left end */
    right: 10px; /* Adjust the width of the gap at the right end */
    top: 0;
    bottom: 0;
    z-index: 15;
    transition: background-color ease-out .5s;
}

.grid__container {
    margin: 0 auto;
    padding-left: var(--grid-margin);
    padding-right: var(--grid-margin);
    max-width: 102rem;
    box-sizing: border-box;
}

.css-hyum1k-MuiToolbar-root{
    min-height: fit-content;
}

.app-logo{
    height: 96px;
    position: absolute;
    right: 32px;
    width: 96px;
}


.navbar-collapse.expanded{
    height: calc(100% - 93px);
 background-color: white;
 z-index: 1000
}

.table-head{
    font-size: 16px;
}

.expanded {
  background-color: rgba(255, 255, 255, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  overflow: hidden;
}

.css-1ps6pg7-MuiPaper-root{
    overflow-y: scroll;
}


  
//   @media screen and (min-width: 1820px) {
//     .forgot-sub{
//         padding: 0 51px 0 0 !important 
//     }
// }

// @media screen and (min-width: 1820px) {
// .view{
//     min-width: 580px !important;
// }
// }

.css-3cmb81-MuiDataGrid-root .MuiDataGrid-columnHeader:hover .MuiDataGrid-iconButtonContainer{
    visibility: hidden;
}

.dashboard-card.hover-effect {
    position: relative;
    transition: box-shadow 0.3s ease;
    
  }

.dashboard-card.hover-effect::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
   bottom: 0;
    z-index: -1;
    box-shadow: 0 0 0.5px 1px rgba(12, 89, 212, 0); /* Adjust the box-shadow values for the blue tint */
    transition: box-shadow 0.3s ease;
    border-radius: 10px;
    @media (min-width: 320px) {
    }
  }
  
  .dashboard-card.hover-effect:hover::before {
    box-shadow: 0 0 1px 2px rgba(12, 89, 212, 0.7); /* Adjust the box-shadow values for the blue tint */

    @media (min-width: 320px) {
        box-shadow: 0 0 1px 2px rgba(12, 89, 212, 0.7);
    }
  }
  
  
  
.sidebar {
    width: 274px;
    height: 680px;
    top: 118px;
    left: 25px;
    border-radius: 10px;

    @media only screen and (max-width: 767px) {
        height: fit-content;
    }
}



.head {
    width: 73px;
    height: 23px;
    top: 150px;
    left: 49px;
    // font-family: sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;


}

.head2 {
    // width: 144px;
    height: 23px;
    top: 206px;
    left: 50px;
    // font-family: sans-serif;
    font-size: 14px;
    font-weight: 600;
    line-height: 23px;
    letter-spacing: 0em;
    text-align: left;
    color: #7E7E7E;

}

.icn {
    width: 24px;
    height: 24px;
    padding: 2px
}

.c {
    transform: translate(196%, -100%);
    width: fit-content;
    //height: 23px;
    // top: 136px;
    // left: 200px;
    color: #FFFFFF;
}

.card {
    //     width: fit-content!important;
    // height: 120px;
    // top: 18px;
    //left: 180px;
    // font-family: sans-serif;
    // font-weight: 600;
    border-radius: 10px;
}

.card.hover-effect {
    position: relative;
    transition: box-shadow 0.3s ease;
    
  }

.card.hover-effect::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
   bottom: 0;
    z-index: -1;
    box-shadow: 0 0 0.5px 1px rgba(12, 89, 212, 0); /* Adjust the box-shadow values for the blue tint */
    transition: box-shadow 0.3s ease;
    border-radius: 10px;
    @media (min-width: 320px) {
    }
  }
  
  .card.hover-effect:hover::before {
    box-shadow: 0 0 1px 2px rgba(12, 89, 212, 0.7); /* Adjust the box-shadow values for the blue tint */

    @media (min-width: 320px) {
        box-shadow: 0 0 1px 2px rgba(12, 89, 212, 0.7);
    }
  }

.button {
    width: max-content;
    //height: 23px;
    //top: 35px;
    //left: 441px;
    border-radius: 50px;
    // font-family: sans-serif;
    font-size: 12px;
    font-weight: 400;
    //line-height: 23px;
    //letter-spacing: 0em;
    //text-align: left;
    color: #F1F1F1;

}

.op {
    background: #3498DB;
}

.oc {
    width: 114px;
    background: #FF5733;

}

.ip {
    background-color: #F1C40F;
}

.od {
    width: 120px;
    background-color: #543CE7;
}

.it {
    background-color: #9B59B6;
}

.oc2 {
    width: 118px;
    background-color: #2ECC71;
}

.card-txt {
    width: fit-content;
    // font-family: sans-serif;
    font-size: 14px;
    font-weight: 400;
    //line-height: 23px;
    //letter-spacing: 0em;
    //text-align: left;
    color: #7E7E7E;
}